import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ChristmasMessage = () => {
    return (
        <div className="christmas-container">
            <div className="snow">
                <div className="christmas-message">
                    <h1>
                        Merry Christmas!{' '}
                        <img
                            src="https://cdn.prod.website-files.com/6165159719dd625e25dad4ad/61651a8fb939835251c719c5_kiwi-logo-p-500.png"
                            alt="Kiwi Logo"
                            className="kiwi-logo"
                        />
                    </h1>
                    <p>Wishing you joy and happiness this holiday season ✨</p>
                </div>
            </div>
        </div>
    );
};

export default function Layout() {
    const { uid } = useParams();
    const [galleryFiles, setGalleryFiles] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        axios.get(`https://api.iphoto.nz/files/${uid}`).then(response => {
            setGalleryFiles(response.data);
        });
    }, [uid]);

    const handlePrevious = () => {
        setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (selectedImage) {
                if (e.key === 'ArrowLeft') handlePrevious();
                if (e.key === 'ArrowRight') handleNext();
                if (e.key === 'Escape') setSelectedImage(null);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedImage]);

    const handleDownload = (url, e) => {
        e.preventDefault();
        // Open in new tab - this bypasses CORS
        window.open(url, '_blank');
    };

    const images = galleryFiles.flatMap(gallery =>
        gallery.urls?.filter(url => url.includes('-thumb') && !url.includes('._')) || []
    ).map(url => ({
        thumb: `https://api.iphoto.nz/photo?url=${url}`,
        full: `https://api.iphoto.nz/photo?url=${url.replace('-thumb', '')}`
    }));

    return (
        <div className="app-container">
            <div className="content">
                <div className="gallery-container">
                    <div className="gallery-grid">
                        {images.map((image, index) => (
                            <div key={image.thumb} className="gallery-item-container">
                                <div
                                    className="gallery-item"
                                    onClick={() => {
                                        setSelectedImage(image.full);
                                        setCurrentIndex(index);
                                    }}
                                >
                                    <img
                                        src={image.thumb}
                                        alt=""
                                        className="gallery-thumbnail"
                                    />
                                    <div className="gallery-item-overlay">
                                        <svg
                                            viewBox="0 0 24 24"
                                            className="zoom-icon"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </div>
                                </div>
                                <a 
                            href={image.full}
                            className="download-link"
                            onClick={(e) => e.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ cursor: 'pointer' }}
                        >
                            download
                        </a>
                            </div>
                        ))}
                    </div>

                    {selectedImage && (
                        <div className="lightbox" onClick={() => setSelectedImage(null)}>
                            <div className="lightbox-content" onClick={e => e.stopPropagation()}>
                                <button className="lightbox-close" onClick={() => setSelectedImage(null)}>×</button>
                                <button className="lightbox-nav prev" onClick={handlePrevious}>‹</button>
                                <img
                                    src={images[currentIndex].full}
                                    alt=""
                                    className="lightbox-image"
                                />
                                <button className="lightbox-nav next" onClick={handleNext}>›</button>
                                <div className="lightbox-counter">
                                    {currentIndex + 1} / {images.length}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <ChristmasMessage /> */}

            <style>{`
                .app-container {
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                }

                .content {
                    flex: 1;
                }

                .gallery-container {
                    padding: 1rem;
                }

                .gallery-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                    gap: 1rem;
                }

                .gallery-item {
                    position: relative;
                    cursor: pointer;
                    border-radius: 8px;
                    overflow: hidden;
                    aspect-ratio: 1;
                }

                .gallery-thumbnail {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease;
                }

                .gallery-item:hover .gallery-thumbnail {
                    transform: scale(1.1);
                }

                .gallery-item-overlay {
                    position: absolute;
                    inset: 0;
                    background: rgba(0, 0, 0, 0);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 0.3s ease;
                }

                .gallery-item:hover .gallery-item-overlay {
                    background: rgba(0, 0, 0, 0.3);
                }

                .zoom-icon {
                    width: 2rem;
                    height: 2rem;
                    stroke: white;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                .gallery-item:hover .zoom-icon {
                    opacity: 1;
                }

                .download-link {
                    text-align: center;
                    color: #666;
                    text-decoration: none;
                    font-size: 0.875rem;
                    display: block;
                    margin-top: 0.5rem;
                }

                .download-link:hover {
                    text-decoration: underline;
                }

                .lightbox {
                    position: fixed;
                    inset: 0;
                    background: rgba(0, 0, 0, 0.9);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1000;
                }

                .lightbox-content {
                    position: relative;
                    max-width: 90vw;
                    max-height: 90vh;
                }

                .lightbox-image {
                    max-width: 100%;
                    max-height: 90vh;
                    object-fit: contain;
                }

                .lightbox-counter {
                    position: absolute;
                    bottom: -2rem;
                    left: 50%;
                    transform: translateX(-50%);
                    color: white;
                    font-size: 0.875rem;
                }

                .lightbox-close {
                    position: absolute;
                    top: -2rem;
                    right: -2rem;
                    width: 2rem;
                    height: 2rem;
                    border: none;
                    background: none;
                    color: white;
                    font-size: 2rem;
                    cursor: pointer;
                    z-index: 1001;
                }

                .lightbox-nav {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: none;
                    border: none;
                    color: white;
                    font-size: 3rem;
                    cursor: pointer;
                    padding: 1rem;
                    transition: background-color 0.3s ease;
                }

                .lightbox-nav:hover {
                    background: rgba(0, 0, 0, 0.5);
                }

                .prev {
                    left: -4rem;
                }

                .next {
                    right: -4rem;
                }

                .christmas-container {
                    margin: 2rem 0;
                    padding: 2rem;
                    background: #48A1D3;
                    border-radius: 12px;
                    position: relative;
                    overflow: hidden;
                }

                .snow {
                    position: relative;
                }

                .snow::before {
                    content: '';
                    position: absolute;
                    top: -50%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: radial-gradient(4px 4px at 100px 50px, #fff .75px, transparent .75px),
                                radial-gradient(3px 3px at 150px 90px, #fff .5px, transparent .5px),
                                radial-gradient(4px 4px at 50px 160px, #fff .75px, transparent .75px),
                                radial-gradient(3px 3px at 180px 160px, #fff .5px, transparent .5px);
                    background-size: 200px 200px;
                    animation: snow 6s linear infinite;
                    opacity: 0.5;
                }

                .kiwi-logo {
                    height: 2.5rem;
                    vertical-align: middle;
                    margin-left: 0.5rem;
                }

                .christmas-message {
                    text-align: center;
                    padding: 2rem;
                    color: white;
                    text-shadow: 0 2px 4px rgba(0,0,0,0.3);
                    position: relative;
                    z-index: 1;
                }

                .christmas-message h1 {
                    font-size: 2.5rem;
                    margin-bottom: 1rem;
                    animation: glow 2s ease-in-out infinite alternate;
                    color: #e8c547;
                }

                .christmas-message p {
                    font-size: 1.2rem;
                    color: #f0f0f0;
                }

                @keyframes snow {
                    0% { transform: translateY(0); }
                    100% { transform: translateY(200px); }
                }

                @keyframes glow {
                    from {
                        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #e8c547, 0 0 20px #e8c547;
                    }
                    to {
                        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e8c547, 0 0 40px #e8c547;
                    }
                }

                @media (max-width: 768px) {
                    .christmas-message h1 {
                        font-size: 2rem;
                    }
                    .christmas-message p {
                        font-size: 1rem;
                    }
                    .kiwi-logo {
                        height: 2rem;
                    }
                    .lightbox-nav {
                        font-size: 2rem;
                        padding: 0.5rem;
                    }
                    .prev {
                        left: 0;
                    }
                    .next {
                        right: 0;
                    }
                    .lightbox-close {
                        top: 1rem;
                        right: 1rem;
                    }
                    .lightbox-counter {
                        bottom: 1rem;
                    }
                }
            `}</style>
        </div>
    );
}